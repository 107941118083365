import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { auth, googleProvider } from "../../config/firebase";
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { Auth } from "./auth";
import { AdminBody } from "./adminbody";

export const Admin = () => {
  const [user, setUser] = useState(null);
  // Function to check if the user's email is allowed
  const isAllowedEmail = (email) => {
    const allowedEmails = process.env.REACT_APP_ALLOWED_EMAILS.split(",");
    return allowedEmails.includes(email);
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // Check if the user's email is allowed
      if (isAllowedEmail(user.email)) {
        setUser(user);
      } else {
        // Sign out the user if the email is not allowed
        await signOut(auth);
        setUser(null);
        alert(
          "Access denied. You are not authorized to access the admin panel."
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (err) {
      console.error(err);
    }
  };

  // Check if a user is already signed in
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });

  return (
    <>
      <Auth user={user} logout={logout} signIn={signInWithGoogle} />

      {user && <AdminBody />}
    </>
  );
};
