import React, { useState, useEffect } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "../static/css/photogallery.css";
import {
  MDBContainer,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import Footer from "./footer";
import { storage } from "../config/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const PhotoGallery = () => {
  const [index, setIndex] = useState(-1);
  const [imageUrls, setImageUrls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 24; // Adjust this as per your requirement
  const imagesListRef = ref(storage, "photogallery/");
  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await listAll(imagesListRef);
        const urls = await Promise.all(
          response.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return url;
          })
        );
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    };
    fetchImages();
  }, []);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imageUrls.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const slides = currentImages.map((url) => {
    const width = 1080 * 4;
    const height = 720 * 4;
    return {
      src: url, // Use the direct image path here
      width: 1080,
      height: 720,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: url, // Use the direct image path for srcSet as well
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    };
  });

  return (
    <>
      <div>
        <MDBContainer className="photogallerypadding" style={{ height: 700 }}>
          <MDBContainer className="p-4" fluid>
            <PhotoAlbum
              layout="rows"
              photos={slides}
              targetRowHeight={150}
              onClick={({ index: current }) => setIndex(current)}
            />

            <Lightbox
              index={index}
              slides={slides}
              open={index >= 0}
              close={() => setIndex(-1)}
            />
          </MDBContainer>
          <MDBContainer className="text-center">
            <nav aria-label="Page navigation example">
              <MDBPagination center className="mb-0">
                <MDBPaginationItem disabled={currentPage === 1}>
                  <MDBPaginationLink
                    onClick={() => paginate(currentPage - 1)}
                    tabIndex={-1}
                    aria-disabled={currentPage === 1}
                  >
                    Previous
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {[
                  ...Array(Math.ceil(imageUrls.length / imagesPerPage)).keys(),
                ].map((number) => (
                  <MDBPaginationItem
                    key={number + 1}
                    active={number + 1 === currentPage}
                  >
                    <MDBPaginationLink onClick={() => paginate(number + 1)}>
                      {number + 1}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
                <MDBPaginationItem
                  disabled={
                    currentPage === Math.ceil(imageUrls.length / imagesPerPage)
                  }
                >
                  <MDBPaginationLink
                    onClick={() => paginate(currentPage + 1)}
                    aria-disabled={
                      currentPage ===
                      Math.ceil(imageUrls.length / imagesPerPage)
                    }
                  >
                    Next
                  </MDBPaginationLink>
                </MDBPaginationItem>
              </MDBPagination>
            </nav>
          </MDBContainer>
        </MDBContainer>
      </div>
      <div>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default PhotoGallery;
