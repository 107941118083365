import React from "react";
import "../static/css/home.css";
import { useState } from "react";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTypography,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBRipple,
} from "mdb-react-ui-kit";

import useWindowSize from "../utils/userWindowSize";
import Footer from "./footer";
import Testimonials from "../utils/testimonials";
import HiddenTextComponent from "../utils/hidetextcomponent";

const Home = () => {
  const size = useWindowSize();
  return (
    <div className="custom-cursor">
      <MDBContainer className="my-3 text-center" fluid>
        {size.width > 1000 ? (
          <MDBRow center>
            <MDBCol size="4">
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707471242/home/Logo.png"
                  style={{ width: 200, height: "auto" }}
                  className="img-fluid"
                  alt="..."
                />
              </MDBRipple>
            </MDBCol>
            <MDBCol size="4">
              <p className="fs-1 lora-font">New High School Main</p>
              <p className="fs-6 lora-font">Jog Chowk, Amravati, 444601</p>
              <p className="lora-font">
                Join us as we celebrate a century of excellence in education at
                New High School Main 100th-anniversary
                <a href="https://www.canva.com/design/DAF4znLXz68/FjINr3r5GltaB_UMu3Jx9w/view?utm_content=DAF4znLXz68&utm_campaign=designshare&utm_medium=link&utm_source=editor">
                  {" "}
                  Know More...
                </a>
              </p>
            </MDBCol>
            <MDBCol size="4 my-auto">
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707471243/home/dk.png"
                  style={{ width: 200, height: "auto" }}
                  className="img-fluid"
                  alt="..."
                />
              </MDBRipple>
            </MDBCol>
          </MDBRow>
        ) : (
          <>
            <img
              src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707471242/home/Logo.png"
              style={{ width: 400, height: "auto" }}
              className="img-fluid"
              alt="..."
            />
            <p className="pt-1">
              Join us as we celebrate a century of excellence in education at
              New High School Main's 100th-anniversary
              <a href="https://www.canva.com/design/DAF4znLXz68/FjINr3r5GltaB_UMu3Jx9w/view?utm_content=DAF4znLXz68&utm_campaign=designshare&utm_medium=link&utm_source=editor">
                {" "}
                Know More...
              </a>
            </p>
          </>
        )}
      </MDBContainer>
      <div className="carousel-cont">
        <MDBContainer className="px-0" xxl>
          <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem itemId={1} interval={3000}>
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707473073/home/carousel/carousel24.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </MDBRipple>
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2} interval={3000}>
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707474362/home/carousel/carousel22.png"
                  className="d-block w-100"
                  alt="..."
                />
              </MDBRipple>
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3} interval={1700}>
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707473075/home/carousel/carousel3.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </MDBRipple>
            </MDBCarouselItem>
            <MDBCarouselItem itemId={4} interval={1700}>
              <MDBRipple rippleTag="a">
                <img
                  src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707473808/home/carousel/carousel23.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </MDBRipple>
            </MDBCarouselItem>
          </MDBCarousel>
        </MDBContainer>
      </div>
      <div className="youtube-title">
        <p className="fs-1 text-center lora-font m-0 p-4">
          Annual Function 2024
        </p>
      </div>
      <div className="youtube-cont text-center p-5">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol md="5">
              <div className="ratio ratio-16x9">
                <iframe
                  className=""
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/pegw_A54rf8?si=OhQGK-Na_dfFadjN"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </MDBCol>
            <MDBCol md="6">
              <MDBContainer>
                <p className="lora-font fs-3 m-2">
                  Our School's Annual Celebration
                </p>
                <p className="lora-font fs-5 m-2">
                  Through performances, awards, and community participation, we
                  come together to celebrate our shared values and aspirations,
                  fostering a sense of belonging and pride in our school
                  community.
                </p>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div className="p-5 partition"></div>
      <MDBContainer className="get-features p-5" fluid>
        <MDBContainer fluid>
          <MDBRow className="row-cols-1 row-cols-md-3 g-3 text-center">
            <HiddenTextComponent
              className="text-comp"
              title="Interactive Board"
              initialText="Our interactive boards revolutionize classrooms, blending touch-sensitive technology with interactive software for dynamic learning experiences. They create immersive lessons, fostering engagement and transforming traditional teaching spaces into vibrant hubs of learning."
              icon="chalkboard"
            />
            <HiddenTextComponent
              title="Solar Energy Project"
              initialText="Our solar energy project reduces our carbon footprint by
                    harnessing renewable power through strategically placed
                    solar panels, providing clean electricity and serving as an
                    educational tool for environmental conservation."
              icon="solar-panel"
            />
            <HiddenTextComponent
              title="Computer Lab"
              initialText=" Our cutting-edge computer labs empower students to develop
                    digital skills in programming, design, and computing
                    disciplines. High-performance computers and modern software
                    foster innovation, keeping pace with tech advancements and
                    skill develpement."
              icon="desktop"
            />
            <HiddenTextComponent
              title="Seminar Hall"
              initialText="Our spacious seminar hall is a hub for intellectual
                    exchange, designed for seminars, workshops, and conferences.
                    It provides a platform for experts and thought leaders to
                    share insights, fostering a culture of intellectual
                    curiosity among students and the broader community."
              icon="home"
            />
            <HiddenTextComponent
              title="School Bus"
              initialText="Our school vans and buses, ensure safe and reliable
                    transportation, providing students with a secure commute.
                    This service facilitates access to education and gives
                    parents confidence in their children's safety during
                    journeys."
              icon="bus-alt"
            />
            <HiddenTextComponent
              title="Library"
              initialText="Our library, a haven for avid readers, houses a diverse
                    collection of books, journals, and digital resources. With
                    dedicated study areas, it encourages academic excellence and
                    the joy of reading, expanding students' intellectual
                    horizons."
              icon="book-open"
            />
          </MDBRow>
        </MDBContainer>
      </MDBContainer>

      <div className="p-5 partition"></div>

      <div className="principal-section">
        <MDBContainer className="bg_principal text-center py-4" fluid>
          <figure className="fs-2 mb-0">
            <MDBTypography blockquote>
              <p>Message!</p>
            </MDBTypography>
            <figcaption className="blockquote-footer mb-0">
              From the <cite title="Source Title">principal’s Desk!</cite>
            </figcaption>
          </figure>
          <p className="fw-bold lead fs-1"></p>
          <MDBContainer className="text-center">
            <MDBCard className="card_principal shadow">
              <MDBRow className="g-0">
                <MDBCol md="6">
                  <MDBCardImage
                    className="rounded border"
                    src="https://res.cloudinary.com/deb14t8r9/image/upload/v1707473075/home/carousel/principle.jpg"
                    alt="..."
                    fluid
                  />
                </MDBCol>
                <MDBCol
                  className="card_body_principal d-flex align-items-center justify-content-center lora-font"
                  md="6"
                >
                  <MDBCardBody>
                    <MDBCardTitle className="fw-bold">
                      Ku. Varsha B. Jadbansi
                    </MDBCardTitle>
                    <MDBCardText>
                      Dear students, your education is the passport to your
                      future. Embrace challenges, work hard, and believe in
                      yourselves. You have the power to shape your success.
                      Dream big and make us proud and happy!
                    </MDBCardText>
                    <MDBCardText>
                      <small className="text-muted">
                        Administrative Department!
                      </small>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBContainer>

          <p
            style={{ textAlign: "justify" }}
            className="lora-font lh-lg py-5 mobile_update text-center"
          >
            Kailaswasi Dadasaheb Khaparde established New High School Main,
            demonstrating extraordinary vision during its centenary year. We are
            delighted to welcome you to this website. For over a century, our
            school has tirelessly pursued the all-round development of students,
            preserving culture, fostering language skills, and nurturing
            creativity in science and medicine. Embracing change, we continually
            initiate new endeavors for student welfare and growth. Looking
            ahead, we are committed to molding academically proficient and
            culturally enriched individuals. We extend gratitude to all for
            their support, and with your ongoing collaboration, we aim for
            further prosperity. This trust is echoed by our teachers, staff, and
            governing body. We humbly request your continued support in our
            future endeavors.
          </p>
        </MDBContainer>
      </div>

      <div className="p-5 partition"></div>

      <Testimonials />

      <div className="p-5 partition"></div>

      <Footer />
    </div>
  );
};

export default Home;
