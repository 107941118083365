import React from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";

export const Auth = ({ user, logout, signIn }) => {
  return (
    <MDBContainer>
      <p className="fs-2 py-2 px-5 text-center">Admin Panel</p>
      <form>
        {!user && (
          <MDBBtn type="button" onClick={signIn} block>
            Sign in
          </MDBBtn>
        )}
        {user && (
          <MDBBtn type="button" onClick={logout} block>
            Logout
          </MDBBtn>
        )}
      </form>
    </MDBContainer>
  );
};
