// Home.js
import React, { useState } from "react";
import {
  MDBContainer,
  MDBInputGroup,
  MDBTextArea,
  MDBRow,
  MDBCol,
  MDBCheckbox,
  MDBBtn,
  MDBSpinner,
} from "mdb-react-ui-kit";
import "../static/css/contact.css";
import Footer from "./footer";
import Map from "../utils/map";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    // Form validation
    const form = e.target;
    const name = form.elements["user_name"].value;
    const phone = form.elements["user_phone"].value;
    const email = form.elements["user_email"].value;
    const message = form.elements["message"].value;

    if (!name || !phone || !email || !message) {
      setStateMessage("Please fill in all required fields.");
      return;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setStateMessage("Please enter a valid email address.");
      return;
    }

    // Phone number validation (basic validation for demonstration)
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phone)) {
      setStateMessage("Please enter a valid 10-digit phone number.");
      return;
    }
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <>
      <MDBContainer className="text-center contact-cont" fluid>
        <p className="fs-1 fw-bold"> Contact Us!</p>
      </MDBContainer>

      <MDBContainer className="p-5">
        <MDBRow center>
          <MDBCol>
            <div className="text-start">
              <p className="fs-3">Address!</p>
              <p className="fs-6">
                Jog Chowk, Sham Square
                <br />
                Amravati Post,
                <br />
                Amravati-444601.
              </p>

              <p className="fs-3">Call!</p>
              <p className="fs-6">
                Phone : +91 721 295 1588 <br />
                Mobile : +91- 9503653157
                <br />
                Alternate Mobile : +91- 9403053107
              </p>

              <p className="fs-3">Contact Timings!</p>
              <p className="fs-6">
                Monday – Friday : 11 am to 5.30 pm <br />
                Saturday : 7.30 am to 12.30 pm
                <br />
                Email : principal.nhsm@gmail.com
              </p>
            </div>
          </MDBCol>
          <MDBCol>
            <div>
              <p className="fs-4">Have Any Questions? Let Us Know</p>
              <p className="pb-2">_______________</p>
              <form onSubmit={sendEmail}>
                <MDBInputGroup className="mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Name*"
                    name="user_name"
                  />
                </MDBInputGroup>
                <MDBInputGroup className="mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone*"
                    name="user_phone"
                  />
                </MDBInputGroup>
                <MDBInputGroup className="mb-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email*"
                    name="user_email"
                  />
                </MDBInputGroup>
                <MDBInputGroup
                  className="mb-4"
                  textTag="div"
                  textBefore={<MDBCheckbox />}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="From Amravati?"
                    readOnly
                  />
                </MDBInputGroup>

                <MDBInputGroup className="mb-4">
                  <MDBTextArea
                    label="Message*"
                    id="textAreaExample"
                    rows={4}
                    name="message"
                  />
                </MDBInputGroup>
                <div className="text-center py-1">
                  {isSubmitting ? (
                    <MDBSpinner role="status">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    <MDBBtn
                      outline
                      rounded
                      type="submit"
                      value="Send"
                      className="mx-auto"
                      color="dark"
                      disabled={isSubmitting}
                    >
                      Submit
                    </MDBBtn>
                  )}
                </div>
                <div style={{ color: "#FF0000" }} className="fs-6 lora-font">
                  {stateMessage && <p>{stateMessage}</p>}
                </div>
              </form>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBContainer className="pb-5 px-5">
        <Map />
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Contact;

//AIzaSyDmns_oO-ywOHEObdWcN1AtNQHAEoMetvA
