import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import Footer from "./footer";
import { db } from "../config/firebase";
import { getDocs, collection } from "firebase/firestore";

const Events = () => {
  const [imageList, setimageList] = useState([]);
  const imageListRef = collection(db, "images");
  const getimageList = async () => {
    try {
      const data = await getDocs(imageListRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setimageList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getimageList();
  }, []);

  return (
    <div>
      <MDBContainer className="pt-5">
        <MDBRow className="row-cols-1 row-cols-md-3 g-5">
          {imageList.map((photo) => (
            <MDBCol>
              <MDBCard className="h-100 hover-zoom">
                <MDBCardImage
                  src={photo.imageUrl}
                  alt="..."
                  position="top"
                  height={320}
                  width={780}
                />

                <MDBCardFooter>
                  <small className="">{photo.description}</small>
                </MDBCardFooter>
              </MDBCard>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Events;
