import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD49fchfaX3izLmk3Ek1ngrZ70AlaIi3c4",
  authDomain: "nhsm-web.firebaseapp.com",
  projectId: "nhsm-web",
  storageBucket: "nhsm-web.appspot.com",
  messagingSenderId: "772643571613",
  appId: "1:772643571613:web:d9caeccd0423796db55174",
  measurementId: "G-CMNG2G6ZRW",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);
