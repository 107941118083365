import { MDBContainer, MDBCol, MDBFile, MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import { storage, db } from "../../config/firebase";
import { setDoc, doc } from "firebase/firestore";

export const AdminBody = () => {
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUpload1, setFileUpload1] = useState(null);
  const [fileDesc, setFileDesc] = useState(null);
  //uploading gallery files
  const uploadFile = async () => {
    if (!fileUpload) return;

    const acceptedImageTypes = ["image/jpeg", "image/png"];
    if (!acceptedImageTypes.includes(fileUpload.type)) {
      alert("Only JPEG, PNG, and GIF images are allowed.");
      return;
    }

    const filesFolderRef = ref(storage, `photogallery/${fileUpload.name}`);
    try {
      await uploadBytes(filesFolderRef, fileUpload);
      alert(`File ${fileUpload.name} uploaded successfully`);
    } catch (err) {
      console.error(err);
      alert("File upload failed");
    }
  };

  //Uploading alumini files
  const uploadFile1 = async () => {
    if (!fileUpload1) return;

    const acceptedImageTypes = ["image/jpeg", "image/png"];
    if (!acceptedImageTypes.includes(fileUpload1.type)) {
      alert("Only JPEG, PNG, and GIF images are allowed.");
      return;
    }

    const filesFolderRef1 = ref(storage, `alumini/${fileUpload1.name}`);
    try {
      await uploadBytes(filesFolderRef1, fileUpload1);
      alert(`File ${fileUpload1.name} uploaded successfully`);
    } catch (err) {
      console.error(err);
      alert("File upload failed");
    }
  };

  //uploading events files
  const uploadFileDesc = async () => {
    if (!fileDesc) return;

    const acceptedImageTypes = ["image/jpeg", "image/png"];
    if (!acceptedImageTypes.includes(fileDesc.type)) {
      alert("Only JPEG, PNG, and GIF images are allowed.");
      return;
    }

    try {
      // Initialize Firebase Storage (if not already done)
      const storage = getStorage();

      // Create a unique file name (recommended)
      const timestamp = Date.now().toString();
      const uniqueFileName = `${timestamp}-${fileDesc.name}`;

      // Construct the reference to the image in storage
      const filesFolderRef = ref(storage, `events/${uniqueFileName}`);

      // Upload the image and get the download URL
      const snapshot = await uploadBytes(filesFolderRef, fileDesc);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Store the description and URL in Firestore (replace with your Firestore setup)
      const imageData = {
        imageUrl: downloadURL, // Store the URL instead of path
        description: prompt("Enter a description for the image:"),
      };
      await setDoc(doc(db, "images", uniqueFileName), imageData);

      alert(`File ${fileDesc.name} uploaded successfully with description.`);
    } catch (err) {
      console.error(err);
      alert("File upload failed");
    }
  };

  return (
    <>
      <MDBContainer className="pt-5 text-center">
        <div
          className="d-flex align-items-start bg-body-tertiary mb-3"
          style={{ height: "100px" }}
        >
          <MDBCol>
            <p className="fs-3">PhotoGallery File Upload</p>
            <div className="mx-3">
              <MDBFile
                label="upload a compressed file in here!"
                type="file"
                onChange={(e) => setFileUpload(e.target.files[0])}
                id="customFile"
              />
              <div className="pt-3">
                <MDBBtn
                  type="button"
                  onClick={() => uploadFile("this is demo description")}
                  block
                >
                  Submit Gallery File
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
          <MDBCol>
            <p className="fs-3">Alumini File Upload</p>
            <div className="mx-3">
              <MDBFile
                label="upload a compressed file in here!"
                type="file"
                onChange={(e) => setFileUpload1(e.target.files[0])}
                id="customFile"
              />
              <div className="pt-3">
                <MDBBtn type="button" onClick={uploadFile1} block>
                  Submit Alumini File
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
          <MDBCol>
            <p className="fs-3">Events File Upload</p>
            <div className="mx-3">
              <MDBFile
                label="upload a compressed file in here!"
                type="file"
                onChange={(e) => setFileDesc(e.target.files[0])}
                id="customFile"
              />
              <div className="pt-3">
                <MDBBtn type="button" onClick={uploadFileDesc} block>
                  Submit Alumini File
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
        </div>
      </MDBContainer>
    </>
  );
};
