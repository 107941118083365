import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../static/css/alumini.css";
import { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import React from "react";
import Footer from "./footer";
import { storage } from "../config/firebase";
import { ref, listAll, list, getDownloadURL } from "firebase/storage";

const Alumini = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const imagesListRef = ref(storage, "alumini/");

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    speed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
  };
  return (
    <>
      <MDBContainer className="py-5 slider" fluid>
        <Slider {...settings}>
          {imageUrls.map((url, index) => (
            <div key={index}>
              <img className="slide-images" src={url} />
            </div>
          ))}
        </Slider>
      </MDBContainer>

      <div style={{ backgroundColor: "#f98866" }} className="p-5"></div>

      <MDBContainer className="text-center">
        <p className="lora-font fs-2 p-4 mb-5">
          Alumni: the heartbeat of our school's history, the architects of its
          future.
        </p>
        <MDBRow className="row-cols-1 row-cols-md-3 g-4">
          {/* <MDBCol>
            <MDBCard className="h-100">
              <MDBCardImage
                src="https://mdbootstrap.com/img/new/standard/city/041.webp"
                alt="..."
                position="top"
              />
              <MDBCardBody>
                <MDBCardTitle>Pirctures of Alumini's</MDBCardTitle>
                <MDBCardText>
                  This is a description of the pictures--- text below as a
                  natural lead-in to additional content. This content is a
                  little bit longer.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>

      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Alumini;
